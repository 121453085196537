import { BottomNavigation, BottomNavigationAction, Paper, Box, Container, Fab } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

import React, { useEffect } from 'react';
import { AddCircleOutlined } from '@mui/icons-material';
import AddTaskDialog from './dialogs/AddTaskDialog/AddTaskDialog';
function App() {
  const [showAddTask, setShowAddTask] = React.useState(false);

  const navigate = useNavigate();
  let location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search)
  const apiKey = queryParameters.get("apiKey");
  const baseId = queryParameters.get("baseId");
  const tableId = queryParameters.get("tableId");
  const cipherKey = queryParameters.get("cipherKey");
  if (apiKey && tableId && cipherKey && baseId) {

    localStorage.setItem('apiKey', apiKey);
    localStorage.setItem('baseId', baseId);
    localStorage.setItem('tableId', tableId);
    localStorage.setItem('cipherKey', cipherKey);
  }



  return (
    <React.Fragment>
      <Box className="App" sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
      }} >
        <Outlet />
        <Fab color="primary" sx={{
          position: 'absolute',
          bottom: 16,
          right: 16,
        }}
          onClick={() => setShowAddTask(true)}
        >
          <AddIcon />
        </Fab>
      </Box>
      <AddTaskDialog open={showAddTask} onClose={() => setShowAddTask(false)} />
    </React.Fragment>

  );
}

export default App;
