import React from 'react'
import { Navigate } from 'react-router-dom';

function Protected({ children }: any) {
  const apiKey = localStorage.getItem('apiKey');
  const baseId = localStorage.getItem('baseId');
  const tableId = localStorage.getItem('tableId');
  const cipherKey = localStorage.getItem('cipherKey');
  if (!apiKey || !baseId || !cipherKey || !tableId) {
    return <Navigate to="/error" replace />
  }
  return children
}
export default Protected