import { Container } from "@mui/material";
import React from "react";

function ErrorView(props: any) {
  return (
    <Container sx={{ width: "100vw", height: "100vh", pt: 1, pb: 1, display: "flex", justifyContent: "center", alignItems: "center" }} maxWidth="sm">
      Brak danych autoryzacyjnych. Otwórz odpowiedni link.
    </Container>
  )
}

export default ErrorView;