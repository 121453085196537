import { LoadingButton } from "@mui/lab";
import { Box, Button, Container } from "@mui/material";
import React from "react";
import AddTaskDialog from "../../dialogs/AddTaskDialog/AddTaskDialog";
import { TaskService } from "../../services/TaskService";

function TaskView(props: any) {
    const [task, setTask] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [taskService, setTaskService] = React.useState<TaskService>(new TaskService());

    const onClickShow = async () => {
        setIsLoading(true);
        var todayTask = await taskService.getTaskForToday();
        setTask(todayTask || "");
        setIsLoading(false);

    }

    return (
        <Container component="main" sx={{ width: "100vw", height: "100vh", pt: 1, pb: 1, display: "flex", justifyContent: "center", alignItems: "center" }} maxWidth="sm">
            {task && <div onClick={() => setTask("")}>{task}</div>}
            {!task && <LoadingButton loading={isLoading} onClick={onClickShow} variant="contained" size={"large"}>OTWÓRZ ZADANIE</LoadingButton>}

        </Container>
    )
}

export default TaskView;