import { createBrowserRouter, createHashRouter } from "react-router-dom";
import ErrorView from "../views/error/ErrorView";
import ProtectedRoute from "./ProtectedRoute";
import TaskView from "../views/task/TaskView";
import App from "../App";
import AddView from "../views/AddView/AddView";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <ProtectedRoute><TaskView /></ProtectedRoute>,
      },
      // {
      //   path: "/add",
      //   element: <ProtectedRoute><AddView /></ProtectedRoute>,
      // },
      {
        path: "/error",
        element: <ErrorView />,
      }
    ]
  },

]);

export default router;