import AirtableService from "./AirtableService";
import { CipherService } from "./CipherService";

export class TaskService {
    private airtableService?: AirtableService
    private cipherService?: CipherService
    constructor() {
        if (this.hasApiData()) {
            this.airtableService = new AirtableService(localStorage.getItem('apiKey')!, localStorage.getItem('baseId')!, localStorage.getItem('tableId')!);
            this.cipherService = new CipherService(localStorage.getItem('cipherKey')!);
        }

    }

    public async getTasks() {
        var tasks = await this.airtableService?.fetchRecords();
        console.log(tasks);
    }

    public async addTask(task: string) {
        this.airtableService?.addRecord({ Task: this.cipherService?.encrypt(task), "Created Date": (new Date()).toISOString().split('T')[0] });
    }

    public async getTaskForToday() {
        var tasks = await this.airtableService?.fetchRecords();

        if (tasks?.length === 0) {
            return "Brak zadań w bazie danych";
        }



        var today = (new Date()).toISOString().split('T')[0];
        var taskForToday = tasks?.filter(task => task.fields["Using Date"] === today && task.fields["Task"]);
        if (taskForToday?.length === 0) {
            var tasksWithoutUsingDate = tasks?.filter(task => !task.fields["Using Date"] && task.fields["Task"]);
            if (tasksWithoutUsingDate?.length === 0) {
                await this.clearAllUsingDates();
                tasks = await this.airtableService?.fetchRecords();
                tasksWithoutUsingDate = tasks?.filter(task => !task.fields["Using Date"] && task.fields["Task"]);
            }
            var randomTask = tasksWithoutUsingDate![Math.floor(Math.random() * tasksWithoutUsingDate!.length)];
            this.airtableService?.updateRecord(randomTask.id, { "Using Date": today });
            return this.cipherService?.decrypt(randomTask.fields["Task"]);
        } else {
            return this.cipherService?.decrypt(taskForToday![0].fields["Task"]);
        }
    }

    public async clearAllUsingDates() {
        var tasks = await this.airtableService?.fetchRecords();
        var groupedTasks = this.groupTasks(tasks!, 10);
        for (const group of groupedTasks) {
            await this.airtableService?.updateRecords(group.map(task => {
                return {
                    id: task.id,
                    fields: {
                        "Using Date": null
                    }
                };
            }));
        }
    }

    private groupTasks(tasks: any[], groupSize: number): any[][] {
        const groupedTasks: any[][] = [];
        for (let i = 0; i < tasks.length; i += groupSize) {
            groupedTasks.push(tasks.slice(i, i + groupSize));
        }
        return groupedTasks;
    }


    public hasApiData() {
        var apiKey = localStorage.getItem('apiKey');
        var baseId = localStorage.getItem('baseId');
        var tableId = localStorage.getItem('tableId');
        var cipherKey = localStorage.getItem('cipherKey');
        return apiKey && tableId && cipherKey && baseId;
    }
}