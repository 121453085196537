import { LoadingButton } from "@mui/lab";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from "@mui/material"
import React from "react"
import { TaskService } from "../../services/TaskService";

function AddTaskDialog(props: { open: boolean, onClose: () => void }) {
    const [task, setTask] = React.useState("");
    const [saving, setSaving] = React.useState(false);
    const [openSaveInfo, setOpenSaveInfo] = React.useState(false);
    const [taskService, setTaskService] = React.useState<TaskService>(new TaskService());

    const onClose = () => {
        props.onClose();
        setTask("");
    }

    const saveTask = async () => {
        if (task.length > 0) {
            setSaving(true);
            await taskService.addTask(task);
            setSaving(false);
            setOpenSaveInfo(true);
            setTimeout(() => {
                setOpenSaveInfo(false);
                setTask("");
            }, 2000);
            onClose();
        }
    }

    return (
        <>
            <Dialog open={props.open} onClose={onClose} fullWidth={true}>
                <DialogTitle>Dodaj zadanie</DialogTitle>
                <DialogContent>
                    <TextField
                        disabled={saving}
                        multiline
                        autoFocus
                        margin="dense"
                        id="text"
                        label="Treść zadania"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={task}
                        onChange={(event) => { setTask(event.target.value) }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button disabled={saving} onClick={onClose}>Anuluj</Button>
                    <LoadingButton onClick={saveTask} loading={saving}>Dodaj</LoadingButton>
                </DialogActions>
            </Dialog>
            <Snackbar autoHideDuration={3000} open={openSaveInfo}>
                <Alert severity="success">Zadanie dodane</Alert>
            </Snackbar>
        </>
    )
}

export default AddTaskDialog;