import Airtable from 'airtable';

export class AirtableService {
    private base: Airtable.Base;
    private table: string;

    constructor(apiKey: string, baseId: string, table: string) {
        this.base = new Airtable({ apiKey: apiKey }).base(baseId);
        this.table = table;
    }

    public async fetchRecords(): Promise<Airtable.RecordData<any>[]> {
        return new Promise((resolve, reject) => {
            const records: Airtable.RecordData<any>[] = [];
            this.base(this.table)
                .select({
                    // Dodaj tu dodatkowe opcje jak 'filterByFormula' itd.
                })
                .eachPage(
                    (batch: Airtable.Records<any>, fetchNextPage: () => void) => { records.push(...batch); fetchNextPage(); },
                    (err: Error | null) => {
                        if (err) {
                            reject(err);
                        } else {
                            resolve(records);
                        }
                    }
                );
        });
    }

    public async updateRecords(records: { id: string, fields: { [key: string]: any } }[]): Promise<Airtable.Record<any>[]> {
        return new Promise((resolve, reject) => {
            this.base(this.table).update(records, (err: any, records: any) => {
                if (err) {
                    reject(err);
                } else if (records && records.length > 0) {
                    resolve(records);
                } else {
                    reject(new Error('No records updated'));
                }
            });
        });
    }

    public async updateRecord(recordId: string, updatedFields: { [key: string]: any }): Promise<Airtable.Record<any>> {
        return new Promise((resolve, reject) => {
            this.base(this.table).update([
                {
                    id: recordId,
                    fields: updatedFields,
                },
            ], (err: any, records: any) => {
                if (err) {
                    reject(err);
                } else if (records && records.length > 0) {
                    resolve(records[0]);
                } else {
                    reject(new Error('No records updated'));
                }
            });
        });
    }

    public async addRecord(fields: { [key: string]: any }): Promise<Airtable.Record<any>> {
        return new Promise((resolve, reject) => {
            this.base(this.table).create([
                {
                    fields: fields,
                },
            ], (err: any, records: any) => {
                if (err) {
                    reject(err);
                } else if (records && records.length > 0) {
                    resolve(records[0]);
                } else {
                    reject(new Error('No records added'));
                }
            });
        });
    }
}

export default AirtableService;