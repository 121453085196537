import CryptoJS from 'crypto-js';

export class CipherService {
    private readonly key: string;

    constructor(key: string) {
        this.key = key
    }

    public encrypt(plainText: string): string {
        const plainData = CryptoJS.enc.Utf8.parse(plainText);
        const encrypted = CryptoJS.AES.encrypt(plainData, this.key);
        return encrypted.toString();
    }

    public decrypt(ciphertext: string): string {
        const decrypted = CryptoJS.AES.decrypt(ciphertext, this.key);
        return decrypted.toString(CryptoJS.enc.Utf8);
    }

}
